import { useState } from "react";
import { userLogin } from "../tools/bend";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default function Login() {
  const [emailInput, setEmailInput] = useState("");
  const [passInput, setPassInput] = useState("");
  const [loginMsg, setLoginMsg] = useState("");

  const handleInput = (e) => {
    setEmailInput(e);
  };
  const handlePassInput = (e) => {
    setPassInput(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    userLogin({ email: emailInput, password: passInput }).then((res) => {
      setLoginMsg(res.msg);
      // set the cookie
      cookies.set("TOKEN", res.token, {
        //we give the cookie a name, and value
        path: "/", //we make the cookie available to the root directory, or all paths. We could decide to make it available only within one path
      });
      setEmailInput("");
      setPassInput("");
    });
  };

  return (
    <div>
      {/* below 3 div layers are for decoration purpouses  */}
      <div
        className={`${
          loginMsg === "Login Successful" ? "login" : "log-layer0"
        }`}
      />
      <div
        className={`${
          loginMsg === "Login Successful" ? "login" : "log-layer2"
        }`}
      />
      <div
        className={`${
          loginMsg === "Login Successful" ? "login" : "log-layer1"
        }`}
      />
      <div
        className={`${
          loginMsg === "Login Successful" ? "login" : "log-layer3"
        }`}
      />
      <main
        className={`${loginMsg === "Login Successful" ? "login" : "logout"}`}
      >
        {/* Login Successful */}
        <form onSubmit={handleSubmit}>
          <h2>username:</h2>
          <label htmlFor="username-input">
            <input
              value={emailInput}
              onChange={(event) => handleInput(event.target.value)}
              id="username-input"
            />
          </label>
          <h2>password:</h2>
          <label htmlFor="password-input">
            <input
              type="password"
              value={passInput}
              onChange={(event) => handlePassInput(event.target.value)}
              className="pass-input"
              id="password-input"
            />
          </label>
          <br />
          <button type="submit" className="auth--form-button">
            Submit
          </button>
        </form>
      </main>
    </div>
  );
}
