import PatchDelete from "./PatchDelete";
import Post from "./Post";
import Login from "./Login";
import { getApps } from "../tools/bend";
import { useEffect, useState } from "react";

export default function Upload() {
  const [apps, setApps] = useState([]);

  useEffect(() => {
    getApps().then((res) => {
      setApps(res);
    });
  }, []);
  //the loading logic is to prevent AppCard being pass apps=undefined since the first time the Upload components renders apps is eqaul [] and then useEffect is executed apps is set to an array of objects and Upload re-renders passing the array to AppCard
  if (!apps.length) {
    return <h1 style={{ marginTop: "2em" }}>Loading...</h1>;
  }

  return (
    <div style={{ marginTop: "3em" }}>
      <div className="upload--align">
        <Post />
        <Login />
      </div>
      <div id="upload-grid">
        {apps.reverse().map((app) => {
          return <PatchDelete appData={app} key={app.app_name} />;
        })}
      </div>
    </div>
  );
}
