export default function Bio() {
  return (
    <div id="bio-cont">
      <div id="bio-stage"></div>
      <h1 className="title">
        Hi,
        <br />
        and welcome!
        <br />
        <br />
        let me <br />
        introduce
        <br /> myself...
      </h1>
      <section id="bio-txt">
        <p>
          I am a full stack developer ={">"} I build React applications, APIs
          and I work with various technologies, including Express, PSQL and
          MongoDB. My primary programming language is JavaScript.
          <br />
          <br /> I aim to find optimal solutions for complex challenges with a
          keen eye for detail, and I strive to maintain the highest possible
          quality standards in my work.
          <br />
        </p>
      </section>
      <p id="foota">All rights reserved.</p>
    </div>
  );
}
