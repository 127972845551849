import { getApps } from "../tools/bend";
import { useEffect, useState } from "react";
import Project from "./Project";

export default function Projects() {
  const [apps, setApps] = useState([]);
  const [imgH, setImgH] = useState("0px");

  useEffect(() => {
    getApps().then((res) => {
      //below we filter-out the images with height greater than width since they mess up the calculation for the grid
      const smallestApp = res
        .filter((a) => parseInt(a.img_width) > parseInt(a.img_height))
        .sort((a, b) => a.img_height - b.img_height)[0];
      //gridColWidth equals half of 70% of the viewport width if the width of viewport is bigger than 850 px; otherwise it will equlas the whole viewpor width
      const gridColWidth =
        window.innerWidth < 850
          ? window.innerWidth
          : (window.innerWidth * 0.7) / 2;
      //appsMaxHeight equals the reduced height of the smallest app height in proportion to the width of the grid column ...assuming the width of the app is bigger than the width of the column
      const appsMaxHeight =
        (smallestApp.imgHeight * gridColWidth) / smallestApp.imgWidth;
      setImgH(appsMaxHeight);
      setApps(res);
    });
  }, []);

  if (!apps.length) {
    return <h1 style={{ marginTop: "2em" }}>Loading...</h1>;
  }
  return (
    <div id="projects-box">
      <div id="stage"></div>
      <div id="projects-cont">
        {apps.reverse().map((app) => {
          return <Project app={app} imgH={imgH} key={app.app_name} />;
        })}
      </div>
    </div>
  );
}
