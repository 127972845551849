import { Link } from "react-router-dom";

export default function Nav() {
  return (
    <div id="link">
      <Link to="/" id="apps">
        Projects
      </Link>
      <Link to="/bio" id="bio">
        Bio
      </Link>
      <Link to="/contact" id="contact">
        Contact
      </Link>
    </div>
  );
}
