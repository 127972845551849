import { Link } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";

export default function Project({ app, imgH }) {
  const [loaded, setLoaded] = useState(false);
  const [inView, setInView] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const currRef = ref.current;
    let isLarger;
    let ratio;
    //note that initially the currRef.clientHeight is 0 but at
    //the time of intersection the element would have been loaded and
    //rendered its full height and loaded is placed as a dependency
    //so when the ref element is fully loaded we define the intersection observer
    if (currRef.clientHeight > 0) {
      //below we set a threshold of 0.5 by default which means once 50% of the element has intersected the viewport the
      //element will appear but in cases where the element's height is larger than the viewport height then we use 'ratio' which
      //equals to the percentage of the element's height that is equivalent to 50% of the viewport's height
      if (currRef.clientHeight > window.innerHeight) {
        isLarger = currRef.clientHeight > window.innerHeight;
        ratio = window.innerHeight / currRef.clientHeight / 2;
      }
      //const contRatio = currRef.clientHeight>window.innerHeight?0.5:
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setInView(true);
          }
        },
        {
          threshold: isLarger ? ratio : 0.5,
        }
      );
      //console.log(currRef.getBoundingClientRect().height);
      observer.observe(currRef);

      return () => {
        //observe.disconnect() is used to remove all obsereved elements from the observer object
        observer.unobserve(currRef);
      };
    }
  }, [loaded]);

  function handleLoad() {
    setLoaded(true);
  }
  return (
    <div
      ref={ref}
      //when the images first load the initial size is small so most of them appear on screen and the effect triggered by intersection of observed elements(images) won't work since the state of variable in-view is always 'true', hence we use the state 'loaded' to initially hide or prevent the images to appear on screen until they are fully loaded (original size) allowing for them to be 'observed' correctly so most of them will be out of sight
      style={{ display: `${loaded ? "block" : "none"}` }}
      //below effects are applied once the 'loaded' state var is true
      className={`projects-app-img non-select vanished ${
        inView ? "in-view" : ""
      }`}
    >
      <Link to={app.app_url} target="_blank">
        <div>
          <h2>{app.app_name}</h2>
          <img
            src={app.img_url}
            alt={app.description}
            onLoad={handleLoad}
            style={{
              maxHeight: imgH,
              objectFit:
                app.img_height > app.img_width ? "scale-down" : "cover",
            }}
          />
        </div>
        <p className="projects-app-description">
          <strong>Project Description</strong>
          <br />
          {app.description}
        </p>
        <p className="align-left" style={{ paddingTop: "0.2vw" }}>
          <strong>TECH Used:</strong> {app.tech}
        </p>
      </Link>
    </div>
  );
}
