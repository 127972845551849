import { useState } from "react";
import { postApp } from "../tools/bend";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default function Post() {
  const [displayFile, setDisplayFile] = useState("");
  const [inputName, setName] = useState("");
  const [inputUrl, setUrl] = useState("");
  const [inputDescription, setDescription] = useState("");
  const [inputTech, setTech] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const app2Post = {
      app_name: inputName,
      app_url: inputUrl,
      img: displayFile,
      description: inputDescription,
      tech: inputTech,
    };
    const cookie = cookies.get("TOKEN");
    postApp(app2Post, cookie).then((res) => {
      console.log(res);
    });
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    filePreview(file);
  };

  const filePreview = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setDisplayFile(reader.result);
    };
  };

  return (
    <div id="post-cont">
      <form className="app-card" onSubmit={handleSubmit}>
        <label htmlFor="post-image-input">
          Select an image
          <input
            type="file"
            name="image"
            onChange={handleFileInput}
            className="pass-input"
            id="post-image-input"
          />
        </label>
        <label htmlFor="post-name-input" className="app-card--cell">
          <p>Name</p>
          <input
            value={inputName}
            onChange={(e) => setName(e.target.value)}
            className="input"
            id="post-name-input"
          />
        </label>
        <label htmlFor="post-url-input" className="app-card--cell">
          <p>Url</p>
          <input
            value={inputUrl}
            onChange={(e) => setUrl(e.target.value)}
            className="input"
            id="post-url-input"
          />
        </label>
        <label htmlFor="post-description-input" className="app-card--cell">
          <p>Description</p>
          <input
            value={inputDescription}
            onChange={(e) => setDescription(e.target.value)}
            className="input"
            id="post-description-input"
          />
        </label>
        <label htmlFor="post-tech-input" className="app-card--cell">
          <p>Tech</p>
          <textarea
            style={{ height: "60px" }}
            value={inputTech}
            onChange={(e) => setTech(e.target.value)}
            className="input"
            id="post-tech-input"
          />
        </label>
        <br />
        <button type="submit" className="auth--form-button">
          Submit
        </button>
      </form>
      <div>
        {displayFile && (
          <img src={displayFile} alt="chosen file" id="post-img" />
        )}
      </div>
    </div>
  );
}
