import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { HashRouter } from 'react-router-dom';

//we use HashRouter instead of BrowserROuter to deploy to GirHub.This type of router uses the hash portion of the URL to keep the UI in sync with the URL since GitHub Pages does not support browser history like your browser does.

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <App />
  </HashRouter>

);
