import { useState } from "react";
import { delApp, updateApp } from "../tools/bend";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default function PatchDelete({ appData }) {
  //update and delete logic
  //form field logic
  //form submit logic for delete and update
  const [inputName, setName] = useState(appData.app_name);
  const [inputUrl, setUrl] = useState(appData.app_url);
  const [inputDescription, setDescription] = useState(appData.description);
  const [inputTech, setTech] = useState(appData.tech);
  //below state controls modal to prevent accidental update/deletion
  const [warning, setWarning] = useState(false);
  const [action, setAction] = useState("");

  const handleDelete = () => {
    if (!warning) {
      setWarning(true);
      setAction("del");
      return;
    }
    const cookie = cookies.get("TOKEN");
    delApp(appData.asset_id, cookie).then((res) => console.log(res));
    setWarning(false);
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    if (!warning) {
      setWarning(true);
      setAction("patch");
      return;
    }
    const cookie = cookies.get("TOKEN");
    const body = {
      app_name: inputName,
      description: inputDescription,
      app_url: inputUrl,
      tech: inputTech,
    };
    updateApp(appData.asset_id, body, cookie).then((res) => console.log(res));
    setWarning(false);
  };

  return (
    <div id="patchdel-cont">
      <div id="modal" style={{ display: `${warning ? "block" : "none"}` }}>
        <h1
          style={{
            zIndex: "100",
            fontSize: "3em",
            marginTop: "40vh",
            color: "red",
          }}
        >
          ARE YOU SURE?
        </h1>
        <button
          className="patch-del-btn non-select"
          style={{ marginRight: "1em", fontWeight: "bold" }}
          onClick={
            action === "patch"
              ? handleUpdate
              : action === "del"
              ? handleDelete
              : () => {}
          }
        >
          YES
        </button>
        <button
          className="patch-del-btn non-select"
          style={{ fontWeight: "bold" }}
          onClick={() => setWarning(false)}
        >
          NO
        </button>
      </div>
      <div>
        <form className="app-card" onSubmit={handleUpdate}>
          <label htmlFor="patch-name-input">
            <p>Name</p>
            <input
              value={inputName}
              onChange={(e) => setName(e.target.value)}
              className="input"
              name="patch-name-input"
            />
          </label>
          <label htmlFor="patch-url-input">
            <p>Url</p>
            <input
              value={inputUrl}
              onChange={(e) => setUrl(e.target.value)}
              className="input"
              name="patch-url-input"
            />
          </label>
          <label htmlFor="patch-description-input">
            <p>Description</p>
            <textarea
              style={{ height: "60px" }}
              value={inputDescription}
              onChange={(e) => setDescription(e.target.value)}
              className="input"
              name="patch-description-input"
            />
          </label>
          <label htmlFor="patch-tech-input">
            <p>Tech</p>
            <textarea
              style={{ height: "10em" }}
              value={inputTech}
              onChange={(e) => setTech(e.target.value)}
              className="input"
              name="patch-tech-input"
            />
          </label>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              style={{ width: "50%" }}
              type="submit"
              className="patch-del-btn non-select"
            >
              UPDATE
            </button>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="patch-del-btn non-select"
              onClick={handleDelete}
            >
              DELETE
            </div>
          </div>
        </form>

        <div>
          <img id="update-img" src={appData.imgUrl} alt={appData.appName} />
        </div>
      </div>
    </div>
  );
}
