import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

export default function Contact() {
  const form = useRef();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [msg, setMsg] = useState("");

  // Create random number of stars between 50 and 150/////////////////////////
  const numStars = Math.floor(Math.random() * 101) + 50;

  const newStars = [];
  for (let i = 0; i < numStars; i++) {
    const size = Math.floor(Math.random() * 3) + 1;
    newStars.push({
      id: i,
      size: size,
      top: `${Math.floor(Math.random() * 101)}vh`,
      left: `${Math.floor(Math.random() * 101)}vw`,
    });
  }
  ///////////////////////////////////////////////////////////////////////////////
  const sendEmail = (e) => {
    e.preventDefault();
    if (email === "" || name === "" || msg === "") {
      alert("Please complete all fields");
      return;
    }
    console.log("hello");
    e.preventDefault();
    emailjs
      .sendForm(
        "service_gkcc213",
        "template_q4adyug",
        form.current,
        "5tvx-PHnfk7mge_x_"
      )
      .then(
        (result) => {
          if (result.text === "OK") alert("Message SENT");
          setEmail("");
          setName("");
          setMsg("");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div id="email">
      <form ref={form} onSubmit={sendEmail}>
        <label htmlFor="input-name">Name</label>
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          name="user_name"
          id="input-name"
        />
        <br />
        <label htmlFor="input-email">Email</label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          name="user_email"
          id="input-email"
        />
        <br />
        <label htmlFor="input-textarea">Message</label>
        <textarea
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
          name="message"
          id="input-textarea"
        />
        <br />
        <button id="contact-btn" type="submit">
          Send
        </button>
      </form>
      <Link to="https://github.com/felcard" aria-label="link to github">
        <div id="github" />
      </Link>
      {/* ------------------------------------------------------------ */}
      <div className="stars-container">
        {newStars.map((star) => (
          <div
            key={star.id}
            className="star"
            style={{
              width: `${star.size}px`,
              height: `${star.size}px`,
              top: star.top,
              left: star.left,
            }}
          ></div>
        ))}
      </div>
      {/* ------------------------------------------------------------ */}
    </div>
  );
}
