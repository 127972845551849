import axios from "axios";

const portApi = axios.create({
    baseURL: "https://psqlfolio.cyclic.app/api"
});

export const getApps = () => {
    return portApi.get("/apps").then(res => res.data.apps);
};

export const userLogin = (user) => {
    return portApi.post("/login", user).then(res => res.data);
};

export const postApp = (app, token) => {
    return portApi.post("/apps", app, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    }).then(res => res.data);
};

export const delApp = (app, token) => {
    return portApi.delete(`/apps/${app}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then(res => res.request.status);
};

export const updateApp = (app, body, token) => {
    return portApi.patch(`/apps/${app}`, body, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    }).then(res => res.data);
};