import './App.css';
import { Route, Routes } from 'react-router-dom';
import Nav from './components/Nav';
import Projects from './components/Projects';
import Upload from './components/Upload';
import Bio from './components/Bio';
import Contact from './components/Contact';

function App() {
  return (
    <div className='app'>
      <Nav />
      <Routes>
        <Route path='/' element={<Projects />} />
        <Route path='/admin' element={<Upload />} />
        <Route path='/bio' element={<Bio />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
